<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <div class="form-group">
                <label>Location</label>
                <select class="form-control" v-model="dataState.formFields.cdiLocationID" @change="getData">
                    <option value="0">All Locations</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Start Date</label>
                <DatePicker v-model="dataState.formFields.dateFrom" :inputAttributes="{class: 'form-control'}" />
            </div>
            <div class="form-group">
                <label>End Date</label>
                <DatePicker v-model="dataState.formFields.dateTo" :inputAttributes="{class: 'form-control'}" />
            </div>

            <button class="btn btn-default" @click="getData">
                Filter
            </button>
        </nav>
    </div>
</template>

<script>
import { store } from '@/store/BuildScheduler.store';
import { dataStore } from '@/store/MaintenanceScheduler.store';
import cdiLocationCache from '@/cache/cdiLocation.cache';
import DatePicker from "@/components/utils/DatePicker";
import AppStore from "@/store/App.store";

export default {
    name: "Nav",
    components: {
        DatePicker
    },
    data() {
        return {
            state: store.state,
            dataState: dataStore.state,
            cache: {
                cdiLocationCache
            }
        }
    },
    created() {
        this.dataState.formFields.cdiLocationID = AppStore.state.user.schedulerLocation
    },
    methods: {
        getData: function() {
            dataStore.getData();
        }
    }
}
</script>

<style scoped>

</style>