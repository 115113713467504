// Only using this as a simple data store as we have all the api functionality we need in the BuildScheduler store
import { store } from "@/store/BuildScheduler.store";

export const dataStore = {
	state: {
		buildSchedule: {
			days: {}
		},
		formFields: {
			cdiLocationID: 0,
			dateFrom: null,
			dateTo: null,
		}
	},
	getFilterParameters: function () {
		return {
			'params[numbers][a.cdi_location_id]': this.state.formFields.cdiLocationID,
			'params[dates][date_from]': this.state.formFields.dateFrom,
			'params[dates][date_to]': this.state.formFields.dateTo
		};
	},
	getData: function () {
		store.getMaintenanceData(
			this.getFilterParameters()
		);
	}
}