<template>
    <div class='sch_tracker' >
        <div class='sch_row' :class="(task.is_late) ? 'scorecard_danger' : 'maintenance_schedule_item'" style='border-top: 0'>
            <div class="sch_col">
                <strong>
                    <router-link :to="`/asset/${task.asset_id}`">
                        {{ task.asset_label }}
                    </router-link>
                </strong>
            </div>
            <div class="sch_col right">
                <div>Asset #{{ task.asset_id }}</div>
            </div>
        </div>
        <div class='sch_row cdi_item'>
            <div class='sch_col'>
                {{ task.name }}
            </div>
            <div class='sch_col' style='text-align: right;'>
                <span class='cdi_clickable cdi_link' @click="showTaskDialog(task)">
                    @maintenance
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Task",
        props: ["task", "scheduledDate"],
        methods: {
            showTaskDialog: function(task) {
                task.scheduled_date = this.scheduledDate;
                this.$emit('taskDialog', task);
            }
        }
    }
</script>

<style scoped>

</style>