<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <div class="form-group">
                <label>Location</label>
                <select class="form-control" v-model="dataState.formFields.cdiLocationID" @change="getData">
                    <option value="0">All Locations</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
        </nav>
    </div>
</template>

<script>
import { store } from '@/store/BuildScheduler.store';
import { dataStore } from '@/store/MeasureScheduler.store';
import cdiLocationCache from '@/cache/cdiLocation.cache';
import AppStore from "@/store/App.store";

export default {
    name: "Nav",
    components: {

    },
    data() {
        return {
            state: store.state,
            dataState: dataStore.state,
            cache: {
                cdiLocationCache
            },
        }
    },
    created() {
        this.dataState.formFields.cdiLocationID = AppStore.state.user.schedulerLocation
        this.getData();
    },
    methods: {
        getData: function() {
              let options={
                  'params[cdi_location_id]' : this.dataState.formFields.cdiLocationID,
                  'params[direction]': this.sortDirection
              }
            store.customAPI('sch_measure/measure_scheduler_json', options)
                .then( result => {this.dataState.data = result;})
        }
    }
}
</script>

<style scoped>

</style>