<template>
    <div>
        <Modal v-if="Object.values(task).length > 0" @close="close()" :heading="`${task.asset_label} Asset #${task.asset_id}`">
            <template v-slot:body>
                <div title="Item Action">
                    <form>
                        <h3>Task Action</h3>
                        <div class="form-inline">
                            <label>User #</label>
                            <input type="text" v-model="formUserID" class="form-control">
                            <input class="btn btn-default" type="button" @click="save" :disabled="maintenancePhotos.length < 7" value="Complete Maintenance">
                        </div>
                    </form>

                    <br />
                    <button @click="uploadFile">Upload Photo</button>
                    <br />

                    <p><strong>Files:</strong></p>
                    <ul>
                        <li v-for="photo in maintenancePhotos" :key="photo.cdi_file_id">
                            <ProtectedLink :type="'download'" :linkType="'link'" :text="photo.cdi_file_title" :id="photo.cdi_file_id" :queryString="''" />
                        </li>
                    </ul>

                    <div v-show="task.cdi_file_id">
                        <h3>View / Print PDFs</h3>
                        <ul>
                            <li>
                                <ProtectedLink :type="'download'" :linkType="'link'" :text="'Maintenance Instructions'" :id="task.cdi_file_id" :queryString="queryString" />
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { store } from '@/store/BuildScheduler.store';
    import { dataStore } from '@/store/MaintenanceScheduler.store';
    import helpers from "@/store/helpers";
    import Modal from '@/components/utils/Modal';
    import ProtectedLink from "@/components/utils/ProtectedLink";

    export default {
        name: "TaskDialog",
        components: {
            Modal,
            ProtectedLink
        },
        props: ['task', 'userID'],
        data() {
            return {
                state: store.state,
                formUserID: this.userID,
                maintenancePhotos: []
            }
        },
        methods: {
            save: function () {
                let options = {
                    'params[user_id]': this.formUserID,
                    'params[asset_maintenance_schedule_id]': this.task.asset_maintenance_schedule_id,
                    'params[scheduled_date]': this.task.scheduled_date,
                    ...dataStore.getFilterParameters()
                };

                store.customAPI('asset/do_maintenance_task', options).then(result => {
                    // Refresh DOM
                    helpers.nestedSet(this.state, 'buildSchedule', result);
                    this.close();
                });
            },
            close: function () {
                this.$emit('closeTaskDialog');
            },
            uploadFile: function () {
                this.$appStore.setFileUploadContext('maintenance_photo');
                this.$appStore.setFileUploadContextData({
                    referenceID: this.task.asset_maintenance_schedule_id,
                    reference2ID: this.task.asset_id
                });
                this.$appStore.setFileUploadCallback(() => {
                    this.loadFiles();
                })
            },
            loadFiles: function() {
                let scope = this;
                if (this.task.asset_maintenance_schedule_id) {
                    let options = {
                        'params[asset_maintenance_schedule_id]': this.task.asset_maintenance_schedule_id,
                        ...dataStore.getFilterParameters()
                    }
                    store.customAPI('asset/load_maintenance_photos', options).then(function(response) {
                        scope.maintenancePhotos = response.maintenance_photos;
                    })
                }
            }
        },
        computed: {
            baseUrl: function () {
                return process.env.VUE_APP_URL;
            },
            queryString: function() {
                const params = helpers.getQSString({ 'params' : this.companyId});
                return `genid=${Math.floor(Math.random()*101)}&${params}`;
            }
        },
        watch: {
            task: function() {
                this.loadFiles();
            }
        }
    }
</script>

<style scoped>

</style>