<template>
    <div>
        <table class="sch5day">
            <tr>
                <td v-for="(day, id) in buildSchedule.days" :key="id">
                    <div class="day_header">{{ day.scheduled_date }}</div>
                    <Task v-for="task in day.tasks" :key="task.asset_maintenance_schedule_id"
                             :task="task"
                             :scheduledDate="day.scheduled_date"
                             v-on:taskDialog="showTaskDialog"
                    />
                </td>
            </tr>
        </table>

        <TaskDialog :task="activeTask" v-on:closeTaskDialog="closeTaskDialog" :userID="user.userID" />
    </div>
</template>

<script>
import TaskDialog from "@/components/schedulers/maintenance_scheduler/TaskDialog";
import Task from "@/components/schedulers/maintenance_scheduler/Task";

export default {
    name: "Container",
    components: {
        Task,
        TaskDialog
    },
    data() {
        return {
            activeTask: {},
        }
    },
    props: ['buildSchedule', 'user'],
    methods: {
        showTaskDialog: function (task) {
            this.activeTask = task;
        },
        closeTaskDialog: function () {
            this.activeTask = {};
        }
    }
}
</script>

<style scoped>
    @import '../../../assets/css/buildsch.css';
</style>